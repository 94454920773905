<template>
  <div class="col mainContent">
    <div class="my-2 my-md-4">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-4 align-items-center rounded-3 border border-secondary">
        <div class="col-xxl-5 p-0 p-md-3 p-lg-4">
          <p class="text-light mb-0">Jetzt mitmachen und deine Zuschauerzahlen erhöhen!</p>
          <h1 class="display-5 fw-bold lh-1">
            Unser kostenloser Push für
            <span class="text-info">dein Stream</span>
          </h1>
          <p class="text-secondary">
            Möchtest du deine Zuschauerzahlen steigern? Unser kostenloser Stream-Push auf RP Streams erhöht deine
            Sichtbarkeit. Einfache Teilnahme: Füge 'rpstreams.de' zu deinem Titel hinzu und erreiche garantierte
            Roleplay-Zuschauer, ohne Kosten oder Verträge.
          </p>
          <div class="input-group mb-3 mt-4">
            <span class="w-50 bg-dark text-light input-group-text border-info" id="basic-addon1">rpstreams.de</span>

            <button class="mt-0 border border-info btn btn-outline-info" @click="copyToClipboard">Kopieren</button>
            <p class="text-warning ms-xxl-2 w-75 mt-1">
              {{ text }}
              <br><span style="font-size: 0.8em;" class="text-secondary">Der Ausdruck "rpstreams.de" sollte im Titel platziert werden und nicht in den Tags. Dieser Begriff kann dem Titel hinzugefügt werden und erfordert keine gesonderte Alleinstellung.</span>
            </p>
          </div>
          <img src="../assets/streamercorp.png" class="img-fluid w-100 mt-4 border border-secondary" />
        </div>
        <div class="col-xxl-7 p-0 p-md-3 pt-4 pt-lg-0 p-lg-5">
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/nomoney.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Keine Kosten</h1>
                <p class="text-secondary">
                  Die Teilnahme an unserem "free sponsored push" Programm ist dauerhaft kostenlos.
                </p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/title.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Einfache Teilnahme</h1>
                <p class="text-secondary">
                  Durch einfügen von "rpstreams.de" in dein Titel, nimmst du automatisch dran teil.
                </p>
              </div>
            </div>
          </div>
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/analysis.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Garantierte Zuschauer</h1>
                <p class="text-secondary">
                  Durch die Teilnahme wird dein Stream automatisch auf unserer Startseite angezeigt.
                </p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/gta.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Roleplay Zuschauer</h1>
                <p class="text-secondary">Dein Stream erreicht ausschließlich Roleplay Spieler und Interessenten.</p>
              </div>
            </div>
          </div>
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/infinity.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Kein Zeitlimit</h1>
                <p class="text-secondary">Du kannst unbegrenzt an unserem "free sponsored push" Programm teilnehmen.</p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/nocontract.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Kein Vertrag</h1>
                <p class="text-secondary">
                  Die Teilnahme benötigt keinen Account und somit auch keine Bindung an unser Projekt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="my-5">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 align-items-center rounded-3 border"></div>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "StreamerCorp",
  data() {
    return {
      text: "Jetzt in dein Titel kopieren und direkt loslegen!",
    };
  },
  methods: {
    copyToClipboard() {
      const value = "rpstreams.de";

      var $temp = document.createElement("input");
      document.body.appendChild($temp);
      $temp.value = value;
      $temp.select();
      document.execCommand("copy");
      document.body.removeChild($temp);
      this.text = "Kopiert!";

      setTimeout(() => {
        this.text = "Jetzt in dein Titel kopieren und direkt loslegen!";
      }, 1000);
    },
  },
};
</script>
